import Link from 'next/link'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon } from '@saatva-bits/pattern-library.components.icon'

import styles from './Breadcrumbs.module.scss'

/**
 *
 * @param {{
*  className: string
* }} props
*/
const Separator = ({ className }) => {
    const slashClasses = classNames('u-hidden--md-down', styles.slashDivider)
    const arrowClasses = classNames('u-hidden--md-up', styles.arrowDivider)

    return <span className={className} aria-hidden="true">
        <span className={slashClasses}>/</span>
        <span className={arrowClasses}>
            <Icon
                name='arrow-left'
                alt='arrow left'
                description='arrow left'
                width='8'
                height='8'
                titleId='arrow-left'
            />
        </span>
    </span>
}

Separator.propTypes = {
    className: PropTypes.string
}

/**
 * @param {object} props
 * @param {{
*      label: string,
*      href: string,
*      useAnchor: boolean?
* }[]} props.breadcrumbs
*/
const Breadcrumbs = ({ breadcrumbs, className }) => {
    const labelClasses = classNames(styles.text)
    const linkClasses = classNames(styles.text, styles.link)
    const liClasses = classNames(styles.listItem)

    return <nav aria-label='breadcrumbs' suppressHydrationWarning={true} className={className}>
        <div className='container'>
            <ol className={styles.list}>
                {breadcrumbs.map(({ label, href, useAnchor = true }) => {
                    const LinkTag = useAnchor ? 'a' : Link
                    const Label = href
                        ? <LinkTag href={href} className={linkClasses}>{label}</LinkTag>
                        : <span className={labelClasses}>{label}</span>

                    // the first seperator should only be shown in there are two or less separators
                    let showSeperator = breadcrumbs.length > 1

                    return <li className={liClasses} key={`${label} ${href}`}>
                        { showSeperator && <Separator /> }
                        { Label }
                    </li>
                })}
            </ol>
        </div>
    </nav>
}

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
        useAnchor: PropTypes.bool
    }))
}

export default Breadcrumbs
